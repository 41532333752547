// This is a generated file, do not edit!
import i18n from 'i18next';

class Copydeck {
    static get cardsSustainabilityLongDescCard6Sustainabillity() { return i18n.t('CardsSustainability.long_desc_card_6_sustainabillity'); }
    static get cardsSustainabilityDescCard3Sustainabillity() { return i18n.t('CardsSustainability.desc_card_3_sustainabillity'); }
    static get cardsSustainabilityTitleCard3Sustainabillity() { return i18n.t('CardsSustainability.title_card_3_sustainabillity'); }
    static get cardsSustainabilityLongDescCard4Sustainabillity() { return i18n.t('CardsSustainability.long_desc_card_4_sustainabillity'); }
    static get cardsSustainabilityTitleCard4Sustainabillity() { return i18n.t('CardsSustainability.title_card_4_sustainabillity'); }
    static get cardsSustainabilityDescCard2Sustainabillity() { return i18n.t('CardsSustainability.desc_card_2_sustainabillity'); }
    static get cardsSustainabilityDescCard1Sustainabillity() { return i18n.t('CardsSustainability.desc_card_1_sustainabillity'); }
    static get cardsSustainabilityDescCard6Sustainabillity() { return i18n.t('CardsSustainability.desc_card_6_sustainabillity'); }
    static get cardsSustainabilityLongDescCard5Sustainabillity() { return i18n.t('CardsSustainability.long_desc_card_5_sustainabillity'); }
    static get cardsSustainabilityDescCard5Sustainabillity() { return i18n.t('CardsSustainability.desc_card_5_sustainabillity'); }
    static get cardsSustainabilityLongDescCard3Sustainabillity() { return i18n.t('CardsSustainability.long_desc_card_3_sustainabillity'); }
    static get cardsSustainabilityTitleCard2Sustainabillity() { return i18n.t('CardsSustainability.title_card_2_sustainabillity'); }
    static get cardsSustainabilityTitleCard1Sustainabillity() { return i18n.t('CardsSustainability.title_card_1_sustainabillity'); }
    static get cardsSustainabilityTitleCard6Sustainabillity() { return i18n.t('CardsSustainability.title_card_6_sustainabillity'); }
    static get cardsSustainabilityLongDescCard2Sustainabillity() { return i18n.t('CardsSustainability.long_desc_card_2_sustainabillity'); }
    static get cardsSustainabilityLongDescCard1Sustainabillity() { return i18n.t('CardsSustainability.long_desc_card_1_sustainabillity'); }
    static get cardsSustainabilityTitleCard5Sustainabillity() { return i18n.t('CardsSustainability.title_card_5_sustainabillity'); }
    static get cardsSustainabilityDescCard4Sustainabillity() { return i18n.t('CardsSustainability.desc_card_4_sustainabillity'); }
    static get leaderLeader1Jobtitle() { return i18n.t('Leader.leader1_jobtitle'); }
    static get leaderLeader5Description() { return i18n.t('Leader.leader5_description'); }
    static get leaderLeader1Description() { return i18n.t('Leader.leader1_description'); }
    static get leaderLeader2Description() { return i18n.t('Leader.leader2_description'); }
    static get leaderLeader5Jobtitle() { return i18n.t('Leader.leader5_jobtitle'); }
    static get leaderLeader3Description() { return i18n.t('Leader.leader3_description'); }
    static get leaderLeader3Jobtitle() { return i18n.t('Leader.leader3_jobtitle'); }
    static get leaderLeader4Description() { return i18n.t('Leader.leader4_description'); }
    static get leaderLeader4Jobtitle() { return i18n.t('Leader.leader4_jobtitle'); }
    static get onboardingModalEnter() { return i18n.t('OnboardingModal.enter'); }
    static get benefit4Title() { return i18n.t('benefit_4_title'); }
    static get manageAccountInputFirstName() { return i18n.t('ManageAccount.input_first_name'); }
    static get manageAccountBackTab() { return i18n.t('ManageAccount.back_tab'); }
    static get manageAccountSubmitNewPassword() { return i18n.t('ManageAccount.submit_new_password'); }
    static get manageAccountAreYouSurDeleteAccountTitle() { return i18n.t('ManageAccount.are_you_sur_delete_account_title'); }
    static get manageAccountManageAccountDesc() { return i18n.t('ManageAccount.manage_account_desc'); }
    static get manageAccountInputNewPassword() { return i18n.t('ManageAccount.input_new_password'); }
    static get manageAccountAreYouSurDeleteAccountDesc() { return i18n.t('ManageAccount.are_you_sur_delete_account_desc'); }
    static get manageAccountInputEmail() { return i18n.t('ManageAccount.input_email'); }
    static get manageAccountAreYouSurDeleteAccountSubmit() { return i18n.t('ManageAccount.are_you_sur_delete_account_submit'); }
    static get manageAccountDeleteAccount() { return i18n.t('ManageAccount.delete_account'); }
    static get manageAccountManageAccountTitle() { return i18n.t('ManageAccount.manage_account_title'); }
    static get manageAccountAreYouSurDeleteAccountCancel() { return i18n.t('ManageAccount.are_you_sur_delete_account_cancel'); }
    static get manageAccountInputConfirmNewPassword() { return i18n.t('ManageAccount.input_confirm_new_password'); }
    static get manageAccountInputCompany() { return i18n.t('ManageAccount.input_company'); }
    static get manageAccountManageAccountLogout() { return i18n.t('ManageAccount.manage_account_logout'); }
    static get manageAccountInputCurrentPasswordError() { return i18n.t('ManageAccount.input_current_password_error'); }
    static get manageAccountInputCurrentPassword() { return i18n.t('ManageAccount.input_current_password'); }
    static get manageAccountAreYouSurDeleteAccountSubDesc() { return i18n.t('ManageAccount.are_you_sur_delete_account_sub_desc'); }
    static get manageAccountInputRole() { return i18n.t('ManageAccount.input_role'); }
    static get manageAccountInputLastName() { return i18n.t('ManageAccount.input_last_name'); }
    static get manageAccountUpdatePasswordTitle() { return i18n.t('ManageAccount.update_password_title'); }
    static get manageAccountAccountInformationPageTitle() { return i18n.t('ManageAccount.account_information_page_title'); }
    static get homePageHowItWorksStep3() { return i18n.t('HomePageHowItWorks.step3'); }
    static get homePageHowItWorksStep1() { return i18n.t('HomePageHowItWorks.step1'); }
    static get homePageHowItWorksStep2() { return i18n.t('HomePageHowItWorks.step2'); }
    static get homePageHowItWorksHowItWorksSecondTitle() { return i18n.t('HomePageHowItWorks.how_it_works_second_title'); }
    static get homePageHowItWorksHowItWorksTitle() { return i18n.t('HomePageHowItWorks.how_it_works_title'); }
    static get experienceHowItWorksP3() { return i18n.t('experience_how_it_works_p3'); }
    static get impactsAndInclusionsOurGoals2() { return i18n.t('ImpactsAndInclusions.our_goals_2'); }
    static get impactsAndInclusionsOurGoals8() { return i18n.t('ImpactsAndInclusions.our_goals_8'); }
    static get impactsAndInclusionsCoreValuesSustainabilityTitle() { return i18n.t('ImpactsAndInclusions.core_values_sustainability_title'); }
    static get impactsAndInclusionsHeroSupporting() { return i18n.t('ImpactsAndInclusions.hero_supporting'); }
    static get impactsAndInclusionsOurGoals7() { return i18n.t('ImpactsAndInclusions.our_goals_7'); }
    static get impactsAndInclusionsCoreValuesSustainabilityText3() { return i18n.t('ImpactsAndInclusions.core_values_sustainability_text_3'); }
    static get impactsAndInclusionsOurGoals6() { return i18n.t('ImpactsAndInclusions.our_goals_6'); }
    static get impactsAndInclusionsCoreValuesInnovationText3() { return i18n.t('ImpactsAndInclusions.core_values_innovation_text_3'); }
    static get impactsAndInclusionsCoreValuesIntegrityText1() { return i18n.t('ImpactsAndInclusions.core_values_integrity_text_1'); }
    static get impactsAndInclusionsCoreValuesInclusionText2() { return i18n.t('ImpactsAndInclusions.core_values_inclusion_text_2'); }
    static get impactsAndInclusionsHeroReturns() { return i18n.t('ImpactsAndInclusions.hero_returns'); }
    static get impactsAndInclusionsCoreValuesSustainabilityText4() { return i18n.t('ImpactsAndInclusions.core_values_sustainability_text_4'); }
    static get impactsAndInclusionsOurGoals1() { return i18n.t('ImpactsAndInclusions.our_goals_1'); }
    static get impactsAndInclusionsOurGoalsCurrent() { return i18n.t('ImpactsAndInclusions.our_goals_current'); }
    static get impactsAndInclusionsCoreValuesIntegrityTitle() { return i18n.t('ImpactsAndInclusions.core_values_integrity_title'); }
    static get impactsAndInclusionsCoreValuesInclusionText1() { return i18n.t('ImpactsAndInclusions.core_values_inclusion_text_1'); }
    static get impactsAndInclusionsCoreValuesInclusionText3() { return i18n.t('ImpactsAndInclusions.core_values_inclusion_text_3'); }
    static get impactsAndInclusionsCoreValuesTitle() { return i18n.t('ImpactsAndInclusions.core_values_title'); }
    static get impactsAndInclusionsScrollToLearnMore() { return i18n.t('ImpactsAndInclusions.scroll_to_learn_more'); }
    static get impactsAndInclusionsCoreValuesInnovationText1() { return i18n.t('ImpactsAndInclusions.core_values_innovation_text_1'); }
    static get impactsAndInclusionsOurGoalsFuture() { return i18n.t('ImpactsAndInclusions.our_goals_future'); }
    static get impactsAndInclusionsCoreValuesInnovationTitle() { return i18n.t('ImpactsAndInclusions.core_values_innovation_title'); }
    static get impactsAndInclusionsCoreValuesInclusionTitle() { return i18n.t('ImpactsAndInclusions.core_values_inclusion_title'); }
    static get impactsAndInclusionsOurGoals5() { return i18n.t('ImpactsAndInclusions.our_goals_5'); }
    static get impactsAndInclusionsCoreValuesSubtitle() { return i18n.t('ImpactsAndInclusions.core_values_subtitle'); }
    static get impactsAndInclusionsCoreValuesSustainabilityText2() { return i18n.t('ImpactsAndInclusions.core_values_sustainability_text_2'); }
    static get impactsAndInclusionsHeroInclusive() { return i18n.t('ImpactsAndInclusions.hero_inclusive'); }
    static get impactsAndInclusionsHeroTitles() { return i18n.t('ImpactsAndInclusions.hero_titles'); }
    static get impactsAndInclusionsOurGoals3() { return i18n.t('ImpactsAndInclusions.our_goals_3'); }
    static get impactsAndInclusionsHeroSubtitle() { return i18n.t('ImpactsAndInclusions.hero_subtitle'); }
    static get impactsAndInclusionsCoreValuesSustainabilityText1() { return i18n.t('ImpactsAndInclusions.core_values_sustainability_text_1'); }
    static get impactsAndInclusionsOurGoals4() { return i18n.t('ImpactsAndInclusions.our_goals_4'); }
    static get impactsAndInclusionsImpactInclusionParallax() { return i18n.t('ImpactsAndInclusions.impact_inclusion_parallax'); }
    static get impactsAndInclusionsCoreValuesInnovationText2() { return i18n.t('ImpactsAndInclusions.core_values_innovation_text_2'); }
    static get impactsAndInclusionsCoreValuesIntegrityText2() { return i18n.t('ImpactsAndInclusions.core_values_integrity_text_2'); }
    static get impactsAndInclusionsOurGoalsTitle() { return i18n.t('ImpactsAndInclusions.our_goals_title'); }
    static get headerNavMeetOurLeaders() { return i18n.t('header_nav_meet_our_leaders'); }
    static get beforeTryOnCard() { return i18n.t('before_try_on_card'); }
    static get navPrivacyPolicy() { return i18n.t('nav_privacy_policy'); }
    static get homePageIntellectualSectionCompanyLeadership() { return i18n.t('HomePageIntellectualSection.company_leadership'); }
    static get homePageIntellectualSectionIntellectualTitle() { return i18n.t('HomePageIntellectualSection.intellectual_title'); }
    static get homePageIntellectualSectionParagraph() { return i18n.t('HomePageIntellectualSection.paragraph'); }
    static get homePageIntellectualSectionPatentsPending() { return i18n.t('HomePageIntellectualSection.patents_pending'); }
    static get homePageIntellectualSectionIssued() { return i18n.t('HomePageIntellectualSection.issued'); }
    static get headerNavEngineering() { return i18n.t('header_nav_engineering'); }
    static get navPhotorealistic() { return i18n.t('nav_photorealistic'); }
    static get biometricPolicyBiometricText12() { return i18n.t('BiometricPolicy.biometric_text_12'); }
    static get biometricPolicyBiometicPolicyTitle() { return i18n.t('BiometricPolicy.biometic_policy_title'); }
    static get biometricPolicyBiometricText6() { return i18n.t('BiometricPolicy.biometric_text_6'); }
    static get biometricPolicyBiometricText10() { return i18n.t('BiometricPolicy.biometric_text_10'); }
    static get biometricPolicyBiometricText9() { return i18n.t('BiometricPolicy.biometric_text_9'); }
    static get biometricPolicyBiometricText14() { return i18n.t('BiometricPolicy.biometric_text_14'); }
    static get biometricPolicyBiometricText13() { return i18n.t('BiometricPolicy.biometric_text_13'); }
    static get biometricPolicyBiometricText5() { return i18n.t('BiometricPolicy.biometric_text_5'); }
    static get biometricPolicyBiometricText11() { return i18n.t('BiometricPolicy.biometric_text_11'); }
    static get biometricPolicyBiometricText2() { return i18n.t('BiometricPolicy.biometric_text_2'); }
    static get biometricPolicyBiometricText3() { return i18n.t('BiometricPolicy.biometric_text_3'); }
    static get biometricPolicyBiometricText18() { return i18n.t('BiometricPolicy.biometric_text_18'); }
    static get biometricPolicyBiometricText7() { return i18n.t('BiometricPolicy.biometric_text_7'); }
    static get biometricPolicyBiometricText1() { return i18n.t('BiometricPolicy.biometric_text_1'); }
    static get biometricPolicyBiometricText16() { return i18n.t('BiometricPolicy.biometric_text_16'); }
    static get biometricPolicyBiometricText8() { return i18n.t('BiometricPolicy.biometric_text_8'); }
    static get biometricPolicyBiometricText17() { return i18n.t('BiometricPolicy.biometric_text_17'); }
    static get biometricPolicyBiometricText15() { return i18n.t('BiometricPolicy.biometric_text_15'); }
    static get biometricPolicyBiometricText4() { return i18n.t('BiometricPolicy.biometric_text_4'); }
    static get day() { return i18n.t('day'); }
    static get competitionSpreeP1() { return i18n.t('competition_spree_p1'); }
    static get backToHomePage() { return i18n.t('back_to_home_page'); }
    static get headerNavSupportRessources() { return i18n.t('header_nav_support_ressources'); }
    static get navContactUs() { return i18n.t('nav_contact_us'); }
    static get dpaPolicyDpaText19() { return i18n.t('DpaPolicy.dpa_text_19'); }
    static get dpaPolicyDpaText2() { return i18n.t('DpaPolicy.dpa_text_2'); }
    static get dpaPolicyDpaText80() { return i18n.t('DpaPolicy.dpa_text_80'); }
    static get dpaPolicyDpaText107() { return i18n.t('DpaPolicy.dpa_text_107'); }
    static get dpaPolicyDpaText109() { return i18n.t('DpaPolicy.dpa_text_109'); }
    static get dpaPolicyDpaText20() { return i18n.t('DpaPolicy.dpa_text_20'); }
    static get dpaPolicyDpaTitle4() { return i18n.t('DpaPolicy.dpa_title_4'); }
    static get dpaPolicyDpaText69() { return i18n.t('DpaPolicy.dpa_text_69'); }
    static get dpaPolicyDpaText84() { return i18n.t('DpaPolicy.dpa_text_84'); }
    static get dpaPolicyDpaText7() { return i18n.t('DpaPolicy.dpa_text_7'); }
    static get dpaPolicyDpaText91() { return i18n.t('DpaPolicy.dpa_text_91'); }
    static get dpaPolicyDpaText39() { return i18n.t('DpaPolicy.dpa_text_39'); }
    static get dpaPolicyDpaTitle3() { return i18n.t('DpaPolicy.dpa_title_3'); }
    static get dpaPolicyDpaText13() { return i18n.t('DpaPolicy.dpa_text_13'); }
    static get dpaPolicyDpaText67() { return i18n.t('DpaPolicy.dpa_text_67'); }
    static get dpaPolicyDpaText88() { return i18n.t('DpaPolicy.dpa_text_88'); }
    static get dpaPolicyDpaText87() { return i18n.t('DpaPolicy.dpa_text_87'); }
    static get dpaPolicyDpaText36() { return i18n.t('DpaPolicy.dpa_text_36'); }
    static get dpaPolicyDpaText45() { return i18n.t('DpaPolicy.dpa_text_45'); }
    static get dpaPolicyDpaTitle5() { return i18n.t('DpaPolicy.dpa_title_5'); }
    static get dpaPolicyDpaText53() { return i18n.t('DpaPolicy.dpa_text_53'); }
    static get dpaPolicyDpaText6() { return i18n.t('DpaPolicy.dpa_text_6'); }
    static get dpaPolicyDpaText77() { return i18n.t('DpaPolicy.dpa_text_77'); }
    static get dpaPolicyDpaText96() { return i18n.t('DpaPolicy.dpa_text_96'); }
    static get dpaPolicyDpaText81() { return i18n.t('DpaPolicy.dpa_text_81'); }
    static get dpaPolicyDpaText71() { return i18n.t('DpaPolicy.dpa_text_71'); }
    static get dpaPolicyDpaText8() { return i18n.t('DpaPolicy.dpa_text_8'); }
    static get dpaPolicyDpaText98() { return i18n.t('DpaPolicy.dpa_text_98'); }
    static get dpaPolicyDpaText3() { return i18n.t('DpaPolicy.dpa_text_3'); }
    static get dpaPolicyDpaText70() { return i18n.t('DpaPolicy.dpa_text_70'); }
    static get dpaPolicyDpaText43() { return i18n.t('DpaPolicy.dpa_text_43'); }
    static get dpaPolicyDpaText79() { return i18n.t('DpaPolicy.dpa_text_79'); }
    static get dpaPolicyDpaText76() { return i18n.t('DpaPolicy.dpa_text_76'); }
    static get dpaPolicyDpaText75() { return i18n.t('DpaPolicy.dpa_text_75'); }
    static get dpaPolicyDpaText50() { return i18n.t('DpaPolicy.dpa_text_50'); }
    static get dpaPolicyDpaText72() { return i18n.t('DpaPolicy.dpa_text_72'); }
    static get dpaPolicyDpaText65() { return i18n.t('DpaPolicy.dpa_text_65'); }
    static get dpaPolicyDpaText16() { return i18n.t('DpaPolicy.dpa_text_16'); }
    static get dpaPolicyDpaText14() { return i18n.t('DpaPolicy.dpa_text_14'); }
    static get dpaPolicyDpaText92() { return i18n.t('DpaPolicy.dpa_text_92'); }
    static get dpaPolicyDpaText5() { return i18n.t('DpaPolicy.dpa_text_5'); }
    static get dpaPolicyDpaText27() { return i18n.t('DpaPolicy.dpa_text_27'); }
    static get dpaPolicyDpaText83() { return i18n.t('DpaPolicy.dpa_text_83'); }
    static get dpaPolicyDpaText105() { return i18n.t('DpaPolicy.dpa_text_105'); }
    static get dpaPolicyDpaText68() { return i18n.t('DpaPolicy.dpa_text_68'); }
    static get dpaPolicyDpaText86() { return i18n.t('DpaPolicy.dpa_text_86'); }
    static get dpaPolicyDpaText58() { return i18n.t('DpaPolicy.dpa_text_58'); }
    static get dpaPolicyDpaText113() { return i18n.t('DpaPolicy.dpa_text_113'); }
    static get dpaPolicyDpaText1() { return i18n.t('DpaPolicy.dpa_text_1'); }
    static get dpaPolicyDpaTitle10() { return i18n.t('DpaPolicy.dpa_title_10'); }
    static get dpaPolicyDpaTitle8() { return i18n.t('DpaPolicy.dpa_title_8'); }
    static get dpaPolicyDpaText41() { return i18n.t('DpaPolicy.dpa_text_41'); }
    static get dpaPolicyDpaText52() { return i18n.t('DpaPolicy.dpa_text_52'); }
    static get dpaPolicyDpaText108() { return i18n.t('DpaPolicy.dpa_text_108'); }
    static get dpaPolicyDpaText28() { return i18n.t('DpaPolicy.dpa_text_28'); }
    static get dpaPolicyDpaText95() { return i18n.t('DpaPolicy.dpa_text_95'); }
    static get dpaPolicyDpaText56() { return i18n.t('DpaPolicy.dpa_text_56'); }
    static get dpaPolicyDpaText35() { return i18n.t('DpaPolicy.dpa_text_35'); }
    static get dpaPolicyDpaText26() { return i18n.t('DpaPolicy.dpa_text_26'); }
    static get dpaPolicyDpaText102() { return i18n.t('DpaPolicy.dpa_text_102'); }
    static get dpaPolicyDpaText54() { return i18n.t('DpaPolicy.dpa_text_54'); }
    static get dpaPolicyDpaText12() { return i18n.t('DpaPolicy.dpa_text_12'); }
    static get dpaPolicyDpaText47() { return i18n.t('DpaPolicy.dpa_text_47'); }
    static get dpaPolicyDpaText101() { return i18n.t('DpaPolicy.dpa_text_101'); }
    static get dpaPolicyDpaTitle2() { return i18n.t('DpaPolicy.dpa_title_2'); }
    static get dpaPolicyDpaText89() { return i18n.t('DpaPolicy.dpa_text_89'); }
    static get dpaPolicyDpaText112() { return i18n.t('DpaPolicy.dpa_text_112'); }
    static get dpaPolicyDpaText49() { return i18n.t('DpaPolicy.dpa_text_49'); }
    static get dpaPolicyDpaText104() { return i18n.t('DpaPolicy.dpa_text_104'); }
    static get dpaPolicyDpaText40() { return i18n.t('DpaPolicy.dpa_text_40'); }
    static get dpaPolicyDpaTitle6() { return i18n.t('DpaPolicy.dpa_title_6'); }
    static get dpaPolicyDpaText110() { return i18n.t('DpaPolicy.dpa_text_110'); }
    static get dpaPolicyDpaText62() { return i18n.t('DpaPolicy.dpa_text_62'); }
    static get dpaPolicyDpaText4() { return i18n.t('DpaPolicy.dpa_text_4'); }
    static get dpaPolicyDpaText99() { return i18n.t('DpaPolicy.dpa_text_99'); }
    static get dpaPolicyDpaText100() { return i18n.t('DpaPolicy.dpa_text_100'); }
    static get dpaPolicyDpaTitle7() { return i18n.t('DpaPolicy.dpa_title_7'); }
    static get dpaPolicyDpaText33() { return i18n.t('DpaPolicy.dpa_text_33'); }
    static get dpaPolicyDpaText82() { return i18n.t('DpaPolicy.dpa_text_82'); }
    static get dpaPolicyDpaText37() { return i18n.t('DpaPolicy.dpa_text_37'); }
    static get dpaPolicyDpaText106() { return i18n.t('DpaPolicy.dpa_text_106'); }
    static get dpaPolicyDpaText46() { return i18n.t('DpaPolicy.dpa_text_46'); }
    static get dpaPolicyDpaText59() { return i18n.t('DpaPolicy.dpa_text_59'); }
    static get dpaPolicyDpaTitle1() { return i18n.t('DpaPolicy.dpa_title_1'); }
    static get dpaPolicyDpaText30() { return i18n.t('DpaPolicy.dpa_text_30'); }
    static get dpaPolicyDpaText93() { return i18n.t('DpaPolicy.dpa_text_93'); }
    static get dpaPolicyDpaText29() { return i18n.t('DpaPolicy.dpa_text_29'); }
    static get dpaPolicyDpaText97() { return i18n.t('DpaPolicy.dpa_text_97'); }
    static get dpaPolicyDpaText11() { return i18n.t('DpaPolicy.dpa_text_11'); }
    static get dpaPolicyDpaTitle12() { return i18n.t('DpaPolicy.dpa_title_12'); }
    static get dpaPolicyDpaText66() { return i18n.t('DpaPolicy.dpa_text_66'); }
    static get dpaPolicyDpaText60() { return i18n.t('DpaPolicy.dpa_text_60'); }
    static get dpaPolicyDpaText55() { return i18n.t('DpaPolicy.dpa_text_55'); }
    static get dpaPolicyDpaText24() { return i18n.t('DpaPolicy.dpa_text_24'); }
    static get dpaPolicyDpaTitle9() { return i18n.t('DpaPolicy.dpa_title_9'); }
    static get dpaPolicyDpaText74() { return i18n.t('DpaPolicy.dpa_text_74'); }
    static get dpaPolicyDpaText34() { return i18n.t('DpaPolicy.dpa_text_34'); }
    static get dpaPolicyDpaText63() { return i18n.t('DpaPolicy.dpa_text_63'); }
    static get dpaPolicyDpaText21() { return i18n.t('DpaPolicy.dpa_text_21'); }
    static get dpaPolicyDpaText42() { return i18n.t('DpaPolicy.dpa_text_42'); }
    static get dpaPolicyDpaText90() { return i18n.t('DpaPolicy.dpa_text_90'); }
    static get dpaPolicyDpaText78() { return i18n.t('DpaPolicy.dpa_text_78'); }
    static get dpaPolicyDpaText9() { return i18n.t('DpaPolicy.dpa_text_9'); }
    static get dpaPolicyDpaText10() { return i18n.t('DpaPolicy.dpa_text_10'); }
    static get dpaPolicyDpaText114() { return i18n.t('DpaPolicy.dpa_text_114'); }
    static get dpaPolicyDpaTitle11() { return i18n.t('DpaPolicy.dpa_title_11'); }
    static get dpaPolicyDpaText103() { return i18n.t('DpaPolicy.dpa_text_103'); }
    static get dpaPolicyDpaTitle13() { return i18n.t('DpaPolicy.dpa_title_13'); }
    static get dpaPolicyDpaText111() { return i18n.t('DpaPolicy.dpa_text_111'); }
    static get dpaPolicyDpaText38() { return i18n.t('DpaPolicy.dpa_text_38'); }
    static get dpaPolicyDpaText61() { return i18n.t('DpaPolicy.dpa_text_61'); }
    static get dpaPolicyDpaText57() { return i18n.t('DpaPolicy.dpa_text_57'); }
    static get dpaPolicyDpaText25() { return i18n.t('DpaPolicy.dpa_text_25'); }
    static get dpaPolicyDpaText31() { return i18n.t('DpaPolicy.dpa_text_31'); }
    static get dpaPolicyDpaText32() { return i18n.t('DpaPolicy.dpa_text_32'); }
    static get dpaPolicyDpaText17() { return i18n.t('DpaPolicy.dpa_text_17'); }
    static get dpaPolicyDpaText94() { return i18n.t('DpaPolicy.dpa_text_94'); }
    static get dpaPolicyDpaText18() { return i18n.t('DpaPolicy.dpa_text_18'); }
    static get dpaPolicyDpaText48() { return i18n.t('DpaPolicy.dpa_text_48'); }
    static get dpaPolicyDpaText44() { return i18n.t('DpaPolicy.dpa_text_44'); }
    static get dpaPolicyDpaText22() { return i18n.t('DpaPolicy.dpa_text_22'); }
    static get dpaPolicyDpaText23() { return i18n.t('DpaPolicy.dpa_text_23'); }
    static get dpaPolicyDpaText15() { return i18n.t('DpaPolicy.dpa_text_15'); }
    static get dpaPolicyDpaText51() { return i18n.t('DpaPolicy.dpa_text_51'); }
    static get dpaPolicyDpaText73() { return i18n.t('DpaPolicy.dpa_text_73'); }
    static get dpaPolicyDpaText85() { return i18n.t('DpaPolicy.dpa_text_85'); }
    static get dpaPolicyDpaText64() { return i18n.t('DpaPolicy.dpa_text_64'); }
    static get successPasswordChanged() { return i18n.t('Success.password_changed'); }
    static get competitionOthersP1() { return i18n.t('competition_others_p1'); }
    static get headerNavAboutUsFaq() { return i18n.t('header_nav_about_us_faq'); }
    static get fashionSectionSectionTitle() { return i18n.t('FashionSection.section_title'); }
    static get fashionSectionCtaTitle() { return i18n.t('FashionSection.cta_title'); }
    static get fashionSectionSubtitle() { return i18n.t('FashionSection.subtitle'); }
    static get previousStep() { return i18n.t('previous_step'); }
    static get contactUsToastError() { return i18n.t('contact_us_toast_error'); }
    static get headerNavAboutUs() { return i18n.t('header_nav_about_us'); }
    static get headerNavPartnerRessource() { return i18n.t('header_nav_partner_ressource'); }
    static get joinRevolutionDescription() { return i18n.t('join_revolution_description'); }
    static get keepScrolling() { return i18n.t('keep_scrolling'); }
    static get benefit2Paragraph() { return i18n.t('benefit_2_paragraph'); }
    static get headerNavSupportTicket() { return i18n.t('header_nav_support_ticket'); }
    static get logOut() { return i18n.t('log_out'); }
    static get contactUs() { return i18n.t('contact_us'); }
    static get contactUsToastSuccess() { return i18n.t('contact_us_toast_success'); }
    static get headerNavWhatIsSpreeAi() { return i18n.t('header_nav_what_is_spree_ai'); }
    static get laptop() { return i18n.t('laptop'); }
    static get dashboardPageAccessDashboardComingSoon() { return i18n.t('DashboardPage.access_dashboard_coming_soon'); }
    static get dashboardPageManageAssets() { return i18n.t('DashboardPage.manage_assets'); }
    static get dashboardPagePartnerRessources() { return i18n.t('DashboardPage.partner_ressources'); }
    static get dashboardPageManageAccountSectionDescription() { return i18n.t('DashboardPage.manage_account_section_description'); }
    static get dashboardPagePartnerRessourcesDesc() { return i18n.t('DashboardPage.partner_ressources_desc'); }
    static get dashboardPageManageAccountAccountInformationTitle() { return i18n.t('DashboardPage.manage_account_account_information_title'); }
    static get dashboardPageManageAssetsDesc() { return i18n.t('DashboardPage.manage_assets_desc'); }
    static get dashboardPageCtaLabelManageAccount() { return i18n.t('DashboardPage.cta_label_manage_account'); }
    static get dashboardPageAccessDashboard() { return i18n.t('DashboardPage.access_dashboard'); }
    static get dashboardPageManageAccountSectionTitle() { return i18n.t('DashboardPage.manage_account_section_title'); }
    static get dashboardPageManageAccountUpdatePasswordTitle() { return i18n.t('DashboardPage.manage_account_update_password_title'); }
    static get dashboardPageViewDemoDesc() { return i18n.t('DashboardPage.view_demo_desc'); }
    static get experienceFlowStep3() { return i18n.t('experience_flow_step3'); }
    static get experienceFlowStep2() { return i18n.t('experience_flow_step2'); }
    static get experienceFlowStep5() { return i18n.t('experience_flow_step5'); }
    static get termsTermsText15() { return i18n.t('Terms.terms_text_15'); }
    static get termsTermsText20() { return i18n.t('Terms.terms_text_20'); }
    static get termsTermsText1() { return i18n.t('Terms.terms_text_1'); }
    static get termsTermsText54() { return i18n.t('Terms.terms_text_54'); }
    static get termsTermsText36() { return i18n.t('Terms.terms_text_36'); }
    static get termsTermsText50() { return i18n.t('Terms.terms_text_50'); }
    static get termsTermsText10() { return i18n.t('Terms.terms_text_10'); }
    static get termsTermsText42() { return i18n.t('Terms.terms_text_42'); }
    static get termsTermsText45() { return i18n.t('Terms.terms_text_45'); }
    static get termsTermsText55() { return i18n.t('Terms.terms_text_55'); }
    static get termsTermsText27() { return i18n.t('Terms.terms_text_27'); }
    static get termsTermsText38() { return i18n.t('Terms.terms_text_38'); }
    static get termsTermsText32() { return i18n.t('Terms.terms_text_32'); }
    static get termsTermsText31() { return i18n.t('Terms.terms_text_31'); }
    static get termsTermsText2() { return i18n.t('Terms.terms_text_2'); }
    static get termsTermsText51() { return i18n.t('Terms.terms_text_51'); }
    static get termsTermsText8() { return i18n.t('Terms.terms_text_8'); }
    static get termsTermsText25() { return i18n.t('Terms.terms_text_25'); }
    static get termsTermsText56() { return i18n.t('Terms.terms_text_56'); }
    static get termsTermsText58() { return i18n.t('Terms.terms_text_58'); }
    static get termsTermsText33() { return i18n.t('Terms.terms_text_33'); }
    static termsTermsText14(param1: any) { return i18n.t('Terms.terms_text_14', { param1 }); }
    static get termsTermsText44() { return i18n.t('Terms.terms_text_44'); }
    static get termsTermsText23() { return i18n.t('Terms.terms_text_23'); }
    static get termsTermsText11() { return i18n.t('Terms.terms_text_11'); }
    static get termsTermsText49() { return i18n.t('Terms.terms_text_49'); }
    static get termsTermsText18() { return i18n.t('Terms.terms_text_18'); }
    static get termsTermsText19() { return i18n.t('Terms.terms_text_19'); }
    static get termsTermsText41() { return i18n.t('Terms.terms_text_41'); }
    static get termsTermsText43() { return i18n.t('Terms.terms_text_43'); }
    static get termsTermsText12() { return i18n.t('Terms.terms_text_12'); }
    static get termsTermsText17() { return i18n.t('Terms.terms_text_17'); }
    static get termsTermsText22() { return i18n.t('Terms.terms_text_22'); }
    static get termsTermsText47() { return i18n.t('Terms.terms_text_47'); }
    static get termsTermsText29() { return i18n.t('Terms.terms_text_29'); }
    static get termsTermsText53() { return i18n.t('Terms.terms_text_53'); }
    static get termsTermsText24() { return i18n.t('Terms.terms_text_24'); }
    static get termsTermsText52() { return i18n.t('Terms.terms_text_52'); }
    static get termsTermsText40() { return i18n.t('Terms.terms_text_40'); }
    static get termsTermsText9() { return i18n.t('Terms.terms_text_9'); }
    static get termsTermsText26() { return i18n.t('Terms.terms_text_26'); }
    static get termsTermsText46() { return i18n.t('Terms.terms_text_46'); }
    static get termsTermsText16() { return i18n.t('Terms.terms_text_16'); }
    static get termsTermsText6() { return i18n.t('Terms.terms_text_6'); }
    static get termsTermsText57() { return i18n.t('Terms.terms_text_57'); }
    static get termsTermsText4() { return i18n.t('Terms.terms_text_4'); }
    static get termsTermsText13() { return i18n.t('Terms.terms_text_13'); }
    static get termsTermsText3() { return i18n.t('Terms.terms_text_3'); }
    static get termsTermsText37() { return i18n.t('Terms.terms_text_37'); }
    static get termsTermsText34() { return i18n.t('Terms.terms_text_34'); }
    static get termsTermsText35() { return i18n.t('Terms.terms_text_35'); }
    static get termsTermsText21() { return i18n.t('Terms.terms_text_21'); }
    static get termsTermsText30() { return i18n.t('Terms.terms_text_30'); }
    static get termsTermsText39() { return i18n.t('Terms.terms_text_39'); }
    static get termsTermsText5() { return i18n.t('Terms.terms_text_5'); }
    static get termsTermsText7() { return i18n.t('Terms.terms_text_7'); }
    static get termsTermsText28() { return i18n.t('Terms.terms_text_28'); }
    static get termsTermsText48() { return i18n.t('Terms.terms_text_48'); }
    static get headerNavAboutUsLifeAtSpreeai() { return i18n.t('header_nav_about_us_life_at_spreeai'); }
    static get experienceFlow() { return i18n.t('experience_flow'); }
    static get experienceHeroParagraph() { return i18n.t('experience_hero_paragraph'); }
    static get phoneNumber() { return i18n.t('phone_number'); }
    static get viewMore() { return i18n.t('view_more'); }
    static get clickToDrag() { return i18n.t('click_to_drag'); }
    static get learnMore() { return i18n.t('learn_more'); }
    static get homePageHsharingAndFeedBackSharingCardTitle2() { return i18n.t('HomePageHSharingAndFeedBack.sharing_card_title_2'); }
    static get homePageHsharingAndFeedBackSharingCardText1() { return i18n.t('HomePageHSharingAndFeedBack.sharing_card_text_1'); }
    static get homePageHsharingAndFeedBackSharingCardText2() { return i18n.t('HomePageHSharingAndFeedBack.sharing_card_text_2'); }
    static get homePageHsharingAndFeedBackSharingCardTitle1() { return i18n.t('HomePageHSharingAndFeedBack.sharing_card_title_1'); }
    static get emailConfirmationFlowEmailConfirmationFlowSubtitle() { return i18n.t('EmailConfirmationFlow.email_confirmation_flow_subtitle'); }
    static get emailConfirmationFlowCookieConsentDetail() { return i18n.t('EmailConfirmationFlow.cookie_consent_detail'); }
    static get emailConfirmationFlowEmailConfirmationWaitingApprovalSubtitle() { return i18n.t('EmailConfirmationFlow.email_confirmation_waiting_approval_subtitle'); }
    static get emailConfirmationFlowCookieGoogleAnalyticsDetail() { return i18n.t('EmailConfirmationFlow.cookie_google_analytics_detail'); }
    static get emailConfirmationFlowEmailConfirmationWaitingApproval() { return i18n.t('EmailConfirmationFlow.email_confirmation_waiting_approval'); }
    static get emailConfirmationFlowEmailConfirmationFlowNoEmail() { return i18n.t('EmailConfirmationFlow.email_confirmation_flow_no_email'); }
    static get emailConfirmationFlowEmailConfirmationWaitingApprovalNotification() { return i18n.t('EmailConfirmationFlow.email_confirmation_waiting_approval_notification'); }
    static get emailConfirmationFlowCookieGoogleAnalyticsIdDetail() { return i18n.t('EmailConfirmationFlow.cookie_google_analytics_id_detail'); }
    static get emailConfirmationFlowEmailConfirmationFlowTitle() { return i18n.t('EmailConfirmationFlow.email_confirmation_flow_title'); }
    static get lastName() { return i18n.t('last_name'); }
    static get homePagePartnerTeaserSectionFooter() { return i18n.t('HomePagePartnerTeaserSection.footer'); }
    static get homePagePartnerTeaserSectionTitle() { return i18n.t('HomePagePartnerTeaserSection.title'); }
    static get advantagesForBrands() { return i18n.t('advantages_for_brands'); }
    static get partnerRessourcesPartnerRessourcesHeroDesc() { return i18n.t('PartnerRessources.partner_ressources_hero_desc'); }
    static get partnerRessourcesCardOptionsStandardAdvantage4() { return i18n.t('PartnerRessources.card_options_standard_advantage_4'); }
    static get partnerRessourcesCardOptionsStandardAdvantage2() { return i18n.t('PartnerRessources.card_options_standard_advantage_2'); }
    static get partnerRessourcesPartnerRessourcesHeroTitle() { return i18n.t('PartnerRessources.partner_ressources_hero_title'); }
    static get partnerRessourcesPartnerRessourcesSliderDesc1() { return i18n.t('PartnerRessources.partner_ressources_slider_desc_1'); }
    static get partnerRessourcesPartnerRessourcesSliderDescVideo() { return i18n.t('PartnerRessources.partner_ressources_slider_desc_video'); }
    static get partnerRessourcesPartnerRessourcesTitleRequirementsSections() { return i18n.t('PartnerRessources.partner_ressources_title_requirements_sections'); }
    static get partnerRessourcesPartnerRessourcesSliderDesc3() { return i18n.t('PartnerRessources.partner_ressources_slider_desc_3'); }
    static get partnerRessourcesPartnerRessourcesTitleOptionsSections() { return i18n.t('PartnerRessources.partner_ressources_title_options_sections'); }
    static get partnerRessourcesCardOptionsStandardAdvantage3() { return i18n.t('PartnerRessources.card_options_standard_advantage_3'); }
    static get partnerRessourcesCardOptionsStandardAdvantage1() { return i18n.t('PartnerRessources.card_options_standard_advantage_1'); }
    static get partnerRessourcesPartnerRessourcesSliderDesc2() { return i18n.t('PartnerRessources.partner_ressources_slider_desc_2'); }
    static get partnerRessourcesCardOptionsPremiumTitle() { return i18n.t('PartnerRessources.card_options_premium_title'); }
    static get partnerRessourcesCardOptionsPremiumAdvantage1() { return i18n.t('PartnerRessources.card_options_premium_advantage_1'); }
    static get partnerRessourcesCardOptionsStandardTitle() { return i18n.t('PartnerRessources.card_options_standard_title'); }
    static get partnerRessourcesCardOptionsPremiumAdvantage3() { return i18n.t('PartnerRessources.card_options_premium_advantage_3'); }
    static get partnerRessourcesCardOptionsPremiumAdvantage2() { return i18n.t('PartnerRessources.card_options_premium_advantage_2'); }
    static get partnerRessourcesCardOptionsPremiumAdvantage4() { return i18n.t('PartnerRessources.card_options_premium_advantage_4'); }
    static get role() { return i18n.t('role'); }
    static get advantage2Paragraph() { return i18n.t('advantage_2_paragraph'); }
    static get homePage() { return i18n.t('home_page'); }
    static get benefit5Paragraph() { return i18n.t('benefit_5_paragraph'); }
    static get supportPageTicketSummary() { return i18n.t('SupportPage.ticket_summary'); }
    static get supportPageTicketAttachement() { return i18n.t('SupportPage.ticket_attachement'); }
    static get supportPageTicketDescription() { return i18n.t('SupportPage.ticket_description'); }
    static get supportPageFormSupportTicketDescription() { return i18n.t('SupportPage.form_support_ticket_description'); }
    static get supportPageCreateTicket() { return i18n.t('SupportPage.create_ticket'); }
    static get supportPageFormSupportTicketTitle() { return i18n.t('SupportPage.form_support_ticket_title'); }
    static get supportPageTicketContactEmail() { return i18n.t('SupportPage.ticket_contact_email'); }
    static get unknow() { return i18n.t('unknow'); }
    static get confirmPassword() { return i18n.t('confirm_password'); }
    static get message() { return i18n.t('message'); }
    static get headerNavAboutUsMeetOurTeam() { return i18n.t('header_nav_about_us_meet_our_team'); }
    static get createAccountCompletePartnerAccount() { return i18n.t('CreateAccount.complete_partner_account'); }
    static get createAccountStep3Title5() { return i18n.t('CreateAccount.step_3_title_5'); }
    static get createAccountViewDemoBenefitParagraph() { return i18n.t('CreateAccount.view_demo_benefit_paragraph'); }
    static get createAccountStep3Title4() { return i18n.t('CreateAccount.step_3_title_4'); }
    static get createAccountDidNotReceiveEmail() { return i18n.t('CreateAccount.did_not_receive_email'); }
    static get createAccountStep3Title3() { return i18n.t('CreateAccount.step_3_title_3'); }
    static get createAccountScrollToProgress() { return i18n.t('CreateAccount.scroll_to_progress'); }
    static get createAccountCompleteAccept() { return i18n.t('CreateAccount.complete_accept'); }
    static get createAccountAlreadyAPartnerAlt() { return i18n.t('CreateAccount.already_a_partner_alt'); }
    static get createAccountPartnerRessourcesBenefitParagraph() { return i18n.t('CreateAccount.partner_ressources_benefit_paragraph'); }
    static get createAccountStep1Subtitle() { return i18n.t('CreateAccount.step_1_subtitle'); }
    static get createAccountPartnerBenefits() { return i18n.t('CreateAccount.partner_benefits'); }
    static get createAccountManageAssetsBenefitParagraph() { return i18n.t('CreateAccount.manage_assets_benefit_paragraph'); }
    static get createAccountHome() { return i18n.t('CreateAccount.home'); }
    static get createAccountConfirmationEmailSent() { return i18n.t('CreateAccount.confirmation_email_sent'); }
    static get createAccountCreateAccountLogin() { return i18n.t('CreateAccount.create_account_login'); }
    static get createAccountStep3Title1() { return i18n.t('CreateAccount.step_3_title_1'); }
    static get createAccountResendEmailSuccess() { return i18n.t('CreateAccount.resend_email_success'); }
    static get createAccountStep2Title() { return i18n.t('CreateAccount.step_2_title'); }
    static get createAccountEmailConfirmed() { return i18n.t('CreateAccount.email_confirmed'); }
    static get createAccountEmailConfirmedP2() { return i18n.t('CreateAccount.email_confirmed_p2'); }
    static get createAccountLookAtEmail() { return i18n.t('CreateAccount.look_at_email'); }
    static get createAccountCreateAccountalt1() { return i18n.t('CreateAccount.CreateAccountalt1'); }
    static get createAccountAgreeWithTerms() { return i18n.t('CreateAccount.agree_with_terms'); }
    static get createAccountStep3Title2() { return i18n.t('CreateAccount.step_3_title_2'); }
    static get createAccountBecomePartner() { return i18n.t('CreateAccount.become_partner'); }
    static get createAccountInterestedBecomingSpreeai() { return i18n.t('CreateAccount.interested_becoming_spreeai'); }
    static get createAccountStep2Subtitle() { return i18n.t('CreateAccount.step_2_subtitle'); }
    static get createAccountPartnerAsks() { return i18n.t('CreateAccount.partner_asks'); }
    static get createAccountGetStarted() { return i18n.t('CreateAccount.get_started'); }
    static get createAccountEmailConfirmedP() { return i18n.t('CreateAccount.email_confirmed_p'); }
    static get createAccountStep1Title() { return i18n.t('CreateAccount.step_1_title'); }
    static get createAccountStep3Subtitle() { return i18n.t('CreateAccount.step_3_subtitle'); }
    static get supportTitleForm() { return i18n.t('support_title_form'); }
    static get password() { return i18n.t('password'); }
    static get highQualityImageCreated() { return i18n.t('high_quality_image_created'); }
    static get careersWorkMode() { return i18n.t('Careers.work_mode'); }
    static get careersRemote() { return i18n.t('Careers.remote'); }
    static get careersEmploymentType() { return i18n.t('Careers.employment_type'); }
    static get careersLocation() { return i18n.t('Careers.location'); }
    static get careersJobTitle() { return i18n.t('Careers.job_title'); }
    static get careersHybrid() { return i18n.t('Careers.hybrid'); }
    static get competitionOthersP2() { return i18n.t('competition_others_p2'); }
    static get navTermsOfService() { return i18n.t('nav_terms_of_service'); }
    static get hello() { return i18n.t('hello'); }
    static get email() { return i18n.t('email'); }
    static get homePageExplanationSectionSection() { return i18n.t('HomePageExplanationSection.section'); }
    static get homePageExplanationSectionExplanationTitle() { return i18n.t('HomePageExplanationSection.explanation_title'); }
    static get privacyPolicyTitle() { return i18n.t('privacy_policy_title'); }
    static get copyrightFooter() { return i18n.t('copyright_footer'); }
    static get experienceFlowStep1() { return i18n.t('experience_flow_step1'); }
    static get policyTableOfContentsPolicyTableOfContentsSection11() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_11'); }
    static get policyTableOfContentsPolicyTableOfContentsSection10() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_10'); }
    static get policyTableOfContentsPolicyTableOfContentsSection9() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_9'); }
    static get policyTableOfContentsPolicyTableOfContentsSection4() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_4'); }
    static get policyTableOfContentsPolicyTableOfContentsSection5() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_5'); }
    static get policyTableOfContentsPolicyTableOfContentsSection13() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_13'); }
    static get policyTableOfContentsPolicyTableOfContentsSection14() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_14'); }
    static get policyTableOfContentsPolicyTableOfContentsSection2() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_2'); }
    static get policyTableOfContentsPolicyTableOfContentsSection8() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_8'); }
    static get policyTableOfContentsPolicyTableOfContentsSection1() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_1'); }
    static get policyTableOfContentsPolicyTableOfContentsSection3() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_3'); }
    static get policyTableOfContentsPolicyTableOfContentsSection12() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_12'); }
    static get policyTableOfContentsPolicyTableOfContentsSectionIntroduction() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_introduction'); }
    static get policyTableOfContentsPolicyTableOfContentsSection15() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_15'); }
    static get policyTableOfContentsPolicyTableOfContentsSection7() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_7'); }
    static get policyTableOfContentsPolicyTableOfContentsSection6() { return i18n.t('PolicyTableOfContents.PolicyTableOfContents_section_6'); }
    static get viewDemo() { return i18n.t('view_demo'); }
    static get benefit4Paragraph() { return i18n.t('benefit_4_paragraph'); }
    static get partnerAskPartnerAskQuestionCard2Desc4() { return i18n.t('PartnerAsk.partner_ask_question_card_2_desc_4'); }
    static get partnerAskPartnerAskQuestionCardTitle2() { return i18n.t('PartnerAsk.partner_ask_question_card_title_2'); }
    static get partnerAskPartnerAskTitleDesc1() { return i18n.t('PartnerAsk.partner_ask_title_desc_1'); }
    static get partnerAskPartnerAskQuestionCard2Desc3() { return i18n.t('PartnerAsk.partner_ask_question_card_2_desc_3'); }
    static get partnerAskPartnerAskQuestionCard2Title1() { return i18n.t('PartnerAsk.partner_ask_question_card_2_title_1'); }
    static get partnerAskCompareTryOnResultTitle() { return i18n.t('PartnerAsk.compare_try_on_result_title'); }
    static get partnerAskPartnerAskQuestionCardTitle4() { return i18n.t('PartnerAsk.partner_ask_question_card_title_4'); }
    static get partnerAskPartnerAskQuestionCard2Title2() { return i18n.t('PartnerAsk.partner_ask_question_card_2_title_2'); }
    static get partnerAskPartnerAskTitleSection() { return i18n.t('PartnerAsk.partner_ask_title_section'); }
    static get partnerAskPartnerAskTitleDesc2() { return i18n.t('PartnerAsk.partner_ask_title_desc_2'); }
    static get partnerAskPartnerAskHeaderCardDesc1() { return i18n.t('PartnerAsk.partner_ask_header_card_desc_1'); }
    static get partnerAskPartnerAskQuestionCard2Title4() { return i18n.t('PartnerAsk.partner_ask_question_card_2_title_4'); }
    static get partnerAskPartnerAskHeaderCardTitle1() { return i18n.t('PartnerAsk.partner_ask_header_card_title_1'); }
    static get partnerAskPartnerAskQuestionCard2Desc2() { return i18n.t('PartnerAsk.partner_ask_question_card_2_desc_2'); }
    static get partnerAskPartnerAskQuestionCard2Desc1() { return i18n.t('PartnerAsk.partner_ask_question_card_2_desc_1'); }
    static get partnerAskCompareTryOnResultDesc() { return i18n.t('PartnerAsk.compare_try_on_result_desc'); }
    static get partnerAskCtaViewDataRequirements() { return i18n.t('PartnerAsk.cta_view_data_requirements'); }
    static get partnerAskPartnerAskQuestionCardTitle3() { return i18n.t('PartnerAsk.partner_ask_question_card_title_3'); }
    static get partnerAskBottomPageCtaLabel() { return i18n.t('PartnerAsk.bottom_page_cta_label'); }
    static get partnerAskPartnerAskQuestionCard2Title3() { return i18n.t('PartnerAsk.partner_ask_question_card_2_title_3'); }
    static get partnerAskViewImageBefore() { return i18n.t('PartnerAsk.view_image_before'); }
    static get partnerAskBottomPageCtaTitle() { return i18n.t('PartnerAsk.bottom_page_cta_title'); }
    static get partnerAskPartnerAskHeaderCard2Title1() { return i18n.t('PartnerAsk.partner_ask_header_card_2_title_1'); }
    static get partnerAskPartnerAskQuestionCardDesc4() { return i18n.t('PartnerAsk.partner_ask_question_card_desc_4'); }
    static get partnerAskPartnerAskQuestionCardDesc1() { return i18n.t('PartnerAsk.partner_ask_question_card_desc_1'); }
    static get partnerAskPartnerAskQuestionCardTitle1() { return i18n.t('PartnerAsk.partner_ask_question_card_title_1'); }
    static get partnerAskPartnerAskQuestionCardDesc2() { return i18n.t('PartnerAsk.partner_ask_question_card_desc_2'); }
    static get partnerAskPartnerAskHeaderCard2Desc1() { return i18n.t('PartnerAsk.partner_ask_header_card_2_desc_1'); }
    static get partnerAskPartnerAskQuestionCardDesc3() { return i18n.t('PartnerAsk.partner_ask_question_card_desc_3'); }
    static get joinUs() { return i18n.t('join_us'); }
    static get benefit2Title() { return i18n.t('benefit_2_title'); }
    static get company() { return i18n.t('company'); }
    static get support() { return i18n.t('support'); }
    static get step() { return i18n.t('step'); }
    static get headerNavCulture() { return i18n.t('header_nav_culture'); }
    static get headerNavImpactsAndInclusions() { return i18n.t('header_nav_impacts_and_inclusions'); }
    static get partnerResourcesLink() { return i18n.t('partner_resources_link'); }
    static get scrollDown() { return i18n.t('scroll_down'); }
    static get signup() { return i18n.t('signup'); }
    static get advantage3Title() { return i18n.t('advantage_3_title'); }
    static get benefit6Title() { return i18n.t('benefit_6_title'); }
    static get discoverMore() { return i18n.t('discover_more'); }
    static get update() { return i18n.t('update'); }
    static get afterTryOnCard() { return i18n.t('after_try_on_card'); }
    static get vogueSectionVogueSectionPartnering() { return i18n.t('VogueSection.VogueSectionPartnering'); }
    static get vogueSectionVogueSectionDescription2() { return i18n.t('VogueSection.VogueSectionDescription2'); }
    static get vogueSectionVogueSectionDescription() { return i18n.t('VogueSection.VogueSectionDescription'); }
    static get benefit5Title() { return i18n.t('benefit_5_title'); }
    static get cardLeaderCardLeaderClickToSee() { return i18n.t('CardLeader.card_leader_click_to_see'); }
    static get cardLeaderCardLeaderTapToSee() { return i18n.t('CardLeader.card_leader_tap_to_see'); }
    static get cardLeaderCardLeaderViewLastestBoardMember() { return i18n.t('CardLeader.card_leader_view_lastest_board_member'); }
    static get faqpageFaqAnswer9() { return i18n.t('FAQPage.faq_answer9'); }
    static get faqpageFaqQuestion8() { return i18n.t('FAQPage.faq_question8'); }
    static get faqpageFaqAnswer8() { return i18n.t('FAQPage.faq_answer8'); }
    static get faqpageFaqAnswer7() { return i18n.t('FAQPage.faq_answer7'); }
    static get faqpageFaqAnswer3() { return i18n.t('FAQPage.faq_answer3'); }
    static get faqpageFaqAnswer4() { return i18n.t('FAQPage.faq_answer4'); }
    static get faqpageFaqQuestion5() { return i18n.t('FAQPage.faq_question5'); }
    static get faqpageFaqQuestion9() { return i18n.t('FAQPage.faq_question9'); }
    static get faqpageFaqAnswer10() { return i18n.t('FAQPage.faq_answer10'); }
    static get faqpageFaqQuestion6() { return i18n.t('FAQPage.faq_question6'); }
    static get faqpageFaqQuestion10() { return i18n.t('FAQPage.faq_question10'); }
    static get faqpageFaqAnswer2() { return i18n.t('FAQPage.faq_answer2'); }
    static get faqpageFaqQuestion2() { return i18n.t('FAQPage.faq_question2'); }
    static get faqpageFaqQuestion7() { return i18n.t('FAQPage.faq_question7'); }
    static get faqpageFaqTitle() { return i18n.t('FAQPage.faq_title'); }
    static get faqpageFaqAnswer1() { return i18n.t('FAQPage.faq_answer1'); }
    static get faqpageFaqQuestion3() { return i18n.t('FAQPage.faq_question3'); }
    static get faqpageFaqAnswer5() { return i18n.t('FAQPage.faq_answer5'); }
    static get faqpageFaqQuestion1() { return i18n.t('FAQPage.faq_question1'); }
    static get faqpageFaqQuestion4() { return i18n.t('FAQPage.faq_question4'); }
    static get faqpageFaqAnswer6() { return i18n.t('FAQPage.faq_answer6'); }
    static get blogDetailsReadOurBlogBtn() { return i18n.t('BlogDetails.read_our_blog_btn'); }
    static get blogDetailsLatestUpdatesTitle() { return i18n.t('BlogDetails.latest_updates_title'); }
    static get headerNavBlog() { return i18n.t('header_nav_blog'); }
    static get backToHome() { return i18n.t('back_to_home'); }
    static get companyUrl() { return i18n.t('company_url'); }
    static get lifeAtSpreeAipageJoinTheTeamTitle() { return i18n.t('LifeAtSpreeAIPage.join_the_team_title'); }
    static get lifeAtSpreeAipagePetSectionTitle() { return i18n.t('LifeAtSpreeAIPage.pet_section_title'); }
    static get lifeAtSpreeAipageRemoteTitle() { return i18n.t('LifeAtSpreeAIPage.remote_title'); }
    static get lifeAtSpreeAipageJoinTheTeamCtaCareers() { return i18n.t('LifeAtSpreeAIPage.join_the_team_cta_careers'); }
    static get lifeAtSpreeAipageOurMission() { return i18n.t('LifeAtSpreeAIPage.our_mission'); }
    static get lifeAtSpreeAipageLifeAtSpreeAiTitle() { return i18n.t('LifeAtSpreeAIPage.life_at_spree_ai_title'); }
    static get lifeAtSpreeAipageRemoteDescription() { return i18n.t('LifeAtSpreeAIPage.remote_description'); }
    static get lifeAtSpreeAipageOurMissionP1() { return i18n.t('LifeAtSpreeAIPage.our_mission_p1'); }
    static get lifeAtSpreeAipageOurMissionP2() { return i18n.t('LifeAtSpreeAIPage.our_mission_p2'); }
    static get lifeAtSpreeAipageInclusionDiversityTitle() { return i18n.t('LifeAtSpreeAIPage.inclusion_diversity_title'); }
    static get lifeAtSpreeAipageInclusionDiversityDesc() { return i18n.t('LifeAtSpreeAIPage.inclusion_diversity_desc'); }
    static get month() { return i18n.t('month'); }
    static get heroTitle() { return i18n.t('hero_title'); }
    static get tryOn() { return i18n.t('try_on'); }
    static get forgotPasswordEnterEmailAssociated() { return i18n.t('ForgotPassword.enter_email_associated'); }
    static get forgotPasswordForgotPasswordSend() { return i18n.t('ForgotPassword.forgot_password_send'); }
    static get forgotPasswordResetInstructionsSentParagraph() { return i18n.t('ForgotPassword.reset_instructions_sent_paragraph'); }
    static get forgotPasswordResetInstructionsSent() { return i18n.t('ForgotPassword.reset_instructions_sent'); }
    static get forgotPasswordConfirmNewPassword() { return i18n.t('ForgotPassword.confirm_new_password'); }
    static get forgotPasswordForgotPasswordError() { return i18n.t('ForgotPassword.forgot_password_error'); }
    static get forgotPasswordPasswordResetSuccessParagraph() { return i18n.t('ForgotPassword.password_reset_success_paragraph'); }
    static get forgotPasswordPasswordResetSuccess() { return i18n.t('ForgotPassword.password_reset_success'); }
    static get forgotPasswordAlreadyHavePartnerAccess() { return i18n.t('ForgotPassword.already_have_partner_access'); }
    static get forgotPasswordSetNewPassword() { return i18n.t('ForgotPassword.set_new_password'); }
    static get forgotPasswordNewPassword() { return i18n.t('ForgotPassword.new_password'); }
    static get pageNotFoundParagraph() { return i18n.t('page_not_found_paragraph'); }
    static get customerTermsConditionsCtcText3() { return i18n.t('CustomerTermsConditions.ctc_text_3'); }
    static get customerTermsConditionsCtcText18() { return i18n.t('CustomerTermsConditions.ctc_text_18'); }
    static get customerTermsConditionsCtcText19() { return i18n.t('CustomerTermsConditions.ctc_text_19'); }
    static get customerTermsConditionsCtcText30() { return i18n.t('CustomerTermsConditions.ctc_text_30'); }
    static get customerTermsConditionsCtcText1() { return i18n.t('CustomerTermsConditions.ctc_text_1'); }
    static get customerTermsConditionsCtcText26() { return i18n.t('CustomerTermsConditions.ctc_text_26'); }
    static get customerTermsConditionsCtcText59() { return i18n.t('CustomerTermsConditions.ctc_text_59'); }
    static get customerTermsConditionsCtcText17() { return i18n.t('CustomerTermsConditions.ctc_text_17'); }
    static get customerTermsConditionsCtcText32() { return i18n.t('CustomerTermsConditions.ctc_text_32'); }
    static get customerTermsConditionsCtcText34() { return i18n.t('CustomerTermsConditions.ctc_text_34'); }
    static get customerTermsConditionsCtcText15() { return i18n.t('CustomerTermsConditions.ctc_text_15'); }
    static get customerTermsConditionsCtcText54() { return i18n.t('CustomerTermsConditions.ctc_text_54'); }
    static get customerTermsConditionsCtcText43() { return i18n.t('CustomerTermsConditions.ctc_text_43'); }
    static get customerTermsConditionsCtcText33() { return i18n.t('CustomerTermsConditions.ctc_text_33'); }
    static get customerTermsConditionsCtcText9() { return i18n.t('CustomerTermsConditions.ctc_text_9'); }
    static get customerTermsConditionsCtcText55() { return i18n.t('CustomerTermsConditions.ctc_text_55'); }
    static get customerTermsConditionsCtcText56() { return i18n.t('CustomerTermsConditions.ctc_text_56'); }
    static get customerTermsConditionsCtcText61() { return i18n.t('CustomerTermsConditions.ctc_text_61'); }
    static get customerTermsConditionsCtcText20() { return i18n.t('CustomerTermsConditions.ctc_text_20'); }
    static get customerTermsConditionsCtcText51() { return i18n.t('CustomerTermsConditions.ctc_text_51'); }
    static get customerTermsConditionsCtcText52() { return i18n.t('CustomerTermsConditions.ctc_text_52'); }
    static get customerTermsConditionsCtcText28() { return i18n.t('CustomerTermsConditions.ctc_text_28'); }
    static get customerTermsConditionsCtcText40() { return i18n.t('CustomerTermsConditions.ctc_text_40'); }
    static get customerTermsConditionsCtcText64() { return i18n.t('CustomerTermsConditions.ctc_text_64'); }
    static get customerTermsConditionsCtcText21() { return i18n.t('CustomerTermsConditions.ctc_text_21'); }
    static get customerTermsConditionsCtcText31() { return i18n.t('CustomerTermsConditions.ctc_text_31'); }
    static get customerTermsConditionsCtcText41() { return i18n.t('CustomerTermsConditions.ctc_text_41'); }
    static get customerTermsConditionsCtcText60() { return i18n.t('CustomerTermsConditions.ctc_text_60'); }
    static get customerTermsConditionsCtcTitle() { return i18n.t('CustomerTermsConditions.ctc_title'); }
    static get customerTermsConditionsCtcText58() { return i18n.t('CustomerTermsConditions.ctc_text_58'); }
    static get customerTermsConditionsCtcText63() { return i18n.t('CustomerTermsConditions.ctc_text_63'); }
    static get customerTermsConditionsCtcText38() { return i18n.t('CustomerTermsConditions.ctc_text_38'); }
    static get customerTermsConditionsCtcText49() { return i18n.t('CustomerTermsConditions.ctc_text_49'); }
    static get customerTermsConditionsCtcText44() { return i18n.t('CustomerTermsConditions.ctc_text_44'); }
    static get customerTermsConditionsCtcText57() { return i18n.t('CustomerTermsConditions.ctc_text_57'); }
    static get customerTermsConditionsCtcText47() { return i18n.t('CustomerTermsConditions.ctc_text_47'); }
    static get customerTermsConditionsCtcText35() { return i18n.t('CustomerTermsConditions.ctc_text_35'); }
    static get customerTermsConditionsCtcText11() { return i18n.t('CustomerTermsConditions.ctc_text_11'); }
    static get customerTermsConditionsCtcText13() { return i18n.t('CustomerTermsConditions.ctc_text_13'); }
    static get customerTermsConditionsCtcText27() { return i18n.t('CustomerTermsConditions.ctc_text_27'); }
    static get customerTermsConditionsCtcText46() { return i18n.t('CustomerTermsConditions.ctc_text_46'); }
    static get customerTermsConditionsCtcText62() { return i18n.t('CustomerTermsConditions.ctc_text_62'); }
    static get customerTermsConditionsCtcText22() { return i18n.t('CustomerTermsConditions.ctc_text_22'); }
    static get customerTermsConditionsCtcText14() { return i18n.t('CustomerTermsConditions.ctc_text_14'); }
    static get customerTermsConditionsCtcText12() { return i18n.t('CustomerTermsConditions.ctc_text_12'); }
    static get customerTermsConditionsCtcText6() { return i18n.t('CustomerTermsConditions.ctc_text_6'); }
    static get customerTermsConditionsCtcText4() { return i18n.t('CustomerTermsConditions.ctc_text_4'); }
    static get customerTermsConditionsCtcText7() { return i18n.t('CustomerTermsConditions.ctc_text_7'); }
    static get customerTermsConditionsCtcText5() { return i18n.t('CustomerTermsConditions.ctc_text_5'); }
    static get customerTermsConditionsCtcText24() { return i18n.t('CustomerTermsConditions.ctc_text_24'); }
    static get customerTermsConditionsCtcText8() { return i18n.t('CustomerTermsConditions.ctc_text_8'); }
    static get customerTermsConditionsCtcText53() { return i18n.t('CustomerTermsConditions.ctc_text_53'); }
    static get customerTermsConditionsCtcText10() { return i18n.t('CustomerTermsConditions.ctc_text_10'); }
    static get customerTermsConditionsCtcText2() { return i18n.t('CustomerTermsConditions.ctc_text_2'); }
    static get customerTermsConditionsCtcText45() { return i18n.t('CustomerTermsConditions.ctc_text_45'); }
    static get customerTermsConditionsCtcText29() { return i18n.t('CustomerTermsConditions.ctc_text_29'); }
    static get customerTermsConditionsCtcText48() { return i18n.t('CustomerTermsConditions.ctc_text_48'); }
    static get customerTermsConditionsCtcText16() { return i18n.t('CustomerTermsConditions.ctc_text_16'); }
    static get customerTermsConditionsCtcText36() { return i18n.t('CustomerTermsConditions.ctc_text_36'); }
    static get customerTermsConditionsCtcText25() { return i18n.t('CustomerTermsConditions.ctc_text_25'); }
    static get customerTermsConditionsCtcText42() { return i18n.t('CustomerTermsConditions.ctc_text_42'); }
    static get customerTermsConditionsCtcText50() { return i18n.t('CustomerTermsConditions.ctc_text_50'); }
    static get customerTermsConditionsCtcText39() { return i18n.t('CustomerTermsConditions.ctc_text_39'); }
    static get customerTermsConditionsCtcText37() { return i18n.t('CustomerTermsConditions.ctc_text_37'); }
    static get customerTermsConditionsCtcText23() { return i18n.t('CustomerTermsConditions.ctc_text_23'); }
    static get resendEmail() { return i18n.t('resend_email'); }
    static get benefit3Paragraph() { return i18n.t('benefit_3_paragraph'); }
    static get accountDeletedBodyText3() { return i18n.t('AccountDeleted.body_text_3'); }
    static get accountDeletedBodyText2() { return i18n.t('AccountDeleted.body_text_2'); }
    static get accountDeletedBodyText1() { return i18n.t('AccountDeleted.body_text_1'); }
    static get accountDeletedTitleText() { return i18n.t('AccountDeleted.title_text'); }
    static get logout() { return i18n.t('logout'); }
    static get headerNavCareers() { return i18n.t('header_nav_careers'); }
    static get resetPassword() { return i18n.t('reset_password'); }
    static get change() { return i18n.t('change'); }
    static get goToLogin() { return i18n.t('go_to_login'); }
    static get errorsERequired() { return i18n.t('Errors.e_required'); }
    static get errorsEMax256() { return i18n.t('Errors.e_max_256'); }
    static get errorsPasswordDoesNotMatch() { return i18n.t('Errors.password_does_not_match'); }
    static get errorsEMax75() { return i18n.t('Errors.e_max_75'); }
    static get errorsEUrl() { return i18n.t('Errors.e_url'); }
    static get errorsEPassword() { return i18n.t('Errors.e_password'); }
    static get errorsUserAlreadyExists() { return i18n.t('Errors.user_already_exists'); }
    static get errorsEMax25() { return i18n.t('Errors.e_max_25'); }
    static get errorsEMin8() { return i18n.t('Errors.e_min_8'); }
    static get errorsEMax254() { return i18n.t('Errors.e_max_254'); }
    static errorsEMinChar(param1: any) { return i18n.t('Errors.e_min_char', { param1 }); }
    static get errorsEAcceptedFileTypes() { return i18n.t('Errors.e_accepted_file_types'); }
    static get errorsEMin10() { return i18n.t('Errors.e_min_10'); }
    static get errorsEEmail() { return i18n.t('Errors.e_email'); }
    static get errorsEMin2() { return i18n.t('Errors.e_min_2'); }
    static get errorsEMin5() { return i18n.t('Errors.e_min_5'); }
    static get whatIsSpreeAiWhatIsSpreeAiHeroTitle2() { return i18n.t('WhatIsSpreeAI.what_is_spree_ai_hero_title_2'); }
    static get whatIsSpreeAiWhatIsSpreeAiHeroTitle1() { return i18n.t('WhatIsSpreeAI.what_is_spree_ai_hero_title_1'); }
    static get whatIsSpreeAiWhatIsSpreeAiHeroTitle3() { return i18n.t('WhatIsSpreeAI.what_is_spree_ai_hero_title_3'); }
    static get whatIsSpreeAiWhatIsSpreeAiHeroSubtitle() { return i18n.t('WhatIsSpreeAI.what_is_spree_ai_hero_subtitle'); }
    static get whatIsSpreeAiWhatIsSpreeAiHeroTitle() { return i18n.t('WhatIsSpreeAI.what_is_spree_ai_hero_title'); }
    static get supportSubmitForm() { return i18n.t('support_submit_form'); }
    static get pageForbidden() { return i18n.t('page_forbidden'); }
    static get subject() { return i18n.t('subject'); }
    static get landingPageParteneringWithSpreeai() { return i18n.t('LandingPage.partenering_with_spreeai'); }
    static get landingPagePartenringDetailsIf() { return i18n.t('LandingPage.partenring_details_if'); }
    static get landingPageEnhanceYouBrand() { return i18n.t('LandingPage.enhance_you_brand'); }
    static get landingPageUnlockAmazingStuff() { return i18n.t('LandingPage.unlock_amazing_stuff'); }
    static get contactUsPageSubtitleDirectContact3() { return i18n.t('ContactUsPage.subtitle_direct_contact3'); }
    static get contactUsPageSubtitleDirectContact1() { return i18n.t('ContactUsPage.subtitle_direct_contact1'); }
    static get contactUsPageSubtitleDirectContact2() { return i18n.t('ContactUsPage.subtitle_direct_contact2'); }
    static get advantage4Title() { return i18n.t('advantage_4_title'); }
    static get submit() { return i18n.t('submit'); }
    static get headerNavLifeAtSpreeai() { return i18n.t('header_nav_life_at_spreeai'); }
    static get experienceHowItWorksP1() { return i18n.t('experience_how_it_works_p1'); }
    static get coookieModalNavigation() { return i18n.t('coookie_modal_navigation'); }
    static get others() { return i18n.t('others'); }
    static get requirementsSectionRequirementLabel1() { return i18n.t('RequirementsSection.requirement_label_1'); }
    static get requirementsSectionRequirementLabel3() { return i18n.t('RequirementsSection.requirement_label_3'); }
    static get requirementsSectionRequirementLabel6() { return i18n.t('RequirementsSection.requirement_label_6'); }
    static get requirementsSectionRequirementLabel() { return i18n.t('RequirementsSection.requirement_label'); }
    static get requirementsSectionRequirementLabel5() { return i18n.t('RequirementsSection.requirement_label_5'); }
    static get requirementsSectionRequirementLabel2() { return i18n.t('RequirementsSection.requirement_label_2'); }
    static get requirementsSectionRequirementLabel4() { return i18n.t('RequirementsSection.requirement_label_4'); }
    static get headerNavAboutUsBlog() { return i18n.t('header_nav_about_us_blog'); }
    static get experienceHowItWorksP2() { return i18n.t('experience_how_it_works_p2'); }
    static get advantage3Paragraph() { return i18n.t('advantage_3_paragraph'); }
    static get blogPageSortBy() { return i18n.t('BlogPage.sort_by'); }
    static get blogPageFetchError() { return i18n.t('BlogPage.fetch_error'); }
    static get blogPageBannerImg1Alt() { return i18n.t('BlogPage.banner_img_1_alt'); }
    static get blogPageSortByLatest() { return i18n.t('BlogPage.sort_by_latest'); }
    static get blogPageLinkCopied() { return i18n.t('BlogPage.link_copied'); }
    static get blogPagePlaceholderShared() { return i18n.t('BlogPage.placeholder_shared'); }
    static get blogPageSortByAuthorNameAsc() { return i18n.t('BlogPage.sort_by_authorNameAsc'); }
    static get blogPageBannerBy() { return i18n.t('BlogPage.banner_by'); }
    static get blogPageSortByAuthorNameDesc() { return i18n.t('BlogPage.sort_by_authorNameDesc'); }
    static get blogPageJoinTheFutureTitle() { return i18n.t('BlogPage.join_the_future_title'); }
    static get blogPageBannerTitle() { return i18n.t('BlogPage.banner_title'); }
    static get blogPageBannerImg2Alt() { return i18n.t('BlogPage.banner_img_2_alt'); }
    static get send() { return i18n.t('send'); }
    static get benefit1Title() { return i18n.t('benefit_1_title'); }
    static get headerNavImpactsAndInclusionsWithAnd() { return i18n.t('header_nav_impacts_and_inclusions_with_and'); }
    static get accountEmail() { return i18n.t('account_email'); }
    static get welcomeModalWelcomeModalLeftTitle() { return i18n.t('WelcomeModal.WelcomeModal_leftTitle'); }
    static get welcomeModalWelcomeModalLeftp() { return i18n.t('WelcomeModal.WelcomeModal_leftp'); }
    static get welcomeModalWelcomeModalBtn() { return i18n.t('WelcomeModal.WelcomeModal_btn'); }
    static get welcomeModalWelcomeModalRightp() { return i18n.t('WelcomeModal.WelcomeModal_rightp'); }
    static get welcomeModalWelcomeModalRightTitle() { return i18n.t('WelcomeModal.WelcomeModal_rightTitle'); }
    static get beforeTryOn() { return i18n.t('before_try_on'); }
    static get pageForbiddenParagraph() { return i18n.t('page_forbidden_paragraph'); }
    static get chooseYourModel() { return i18n.t('choose_your_model'); }
    static get experienceAccurateSectionAccurate1ImageTitle() { return i18n.t('ExperienceAccurateSection.accurate_1_image_title'); }
    static get experienceAccurateSectionAccurateP1() { return i18n.t('ExperienceAccurateSection.accurate_p1'); }
    static get experienceAccurateSectionExperienceAccuranteImageAlt2() { return i18n.t('ExperienceAccurateSection.experience_accurante_image_alt_2'); }
    static get experienceAccurateSectionExperienceAccuranteImageAlt3() { return i18n.t('ExperienceAccurateSection.experience_accurante_image_alt_3'); }
    static get experienceAccurateSectionAccurate2ImageDescription() { return i18n.t('ExperienceAccurateSection.accurate_2_image_description'); }
    static get experienceAccurateSectionAccurate1ImageDescription() { return i18n.t('ExperienceAccurateSection.accurate_1_image_description'); }
    static get experienceAccurateSectionExperienceAccuranteImageAlt1() { return i18n.t('ExperienceAccurateSection.experience_accurante_image_alt_1'); }
    static get experienceAccurateSectionAccurateP2() { return i18n.t('ExperienceAccurateSection.accurate_p2'); }
    static get experienceAccurateSectionAccurateTitle() { return i18n.t('ExperienceAccurateSection.accurate_title'); }
    static get experienceAccurateSectionAccurate3ImageTitle() { return i18n.t('ExperienceAccurateSection.accurate_3_image_title'); }
    static get experienceAccurateSectionAccurate2ImageTitle() { return i18n.t('ExperienceAccurateSection.accurate_2_image_title'); }
    static get experienceAccurateSectionAccurateSubtitle() { return i18n.t('ExperienceAccurateSection.accurate_subtitle'); }
    static get experienceAccurateSectionAccurate3ImageDescription() { return i18n.t('ExperienceAccurateSection.accurate_3_image_description'); }
    static get comingSoon() { return i18n.t('coming_soon'); }
    static get termsPolicyTitle() { return i18n.t('terms_policy_title'); }
    static get firstName() { return i18n.t('first_name'); }
    static get homePageBenefitsSectionB4() { return i18n.t('HomePageBenefitsSection.b4'); }
    static get homePageBenefitsSectionB1() { return i18n.t('HomePageBenefitsSection.b1'); }
    static get homePageBenefitsSectionBenefitOurProductBtn() { return i18n.t('HomePageBenefitsSection.benefit_our_product_btn'); }
    static get homePageBenefitsSectionBenefitsCardText1() { return i18n.t('HomePageBenefitsSection.benefits_card_text_1'); }
    static get homePageBenefitsSectionBenefitsCardText0() { return i18n.t('HomePageBenefitsSection.benefits_card_text_0'); }
    static get homePageBenefitsSectionCardB1() { return i18n.t('HomePageBenefitsSection.card_b1'); }
    static get homePageBenefitsSectionB3() { return i18n.t('HomePageBenefitsSection.b3'); }
    static get homePageBenefitsSectionB2() { return i18n.t('HomePageBenefitsSection.b2'); }
    static get homePageBenefitsSectionBenefitSectionTitle() { return i18n.t('HomePageBenefitsSection.benefit_section_title'); }
    static get homePageBenefitsSectionB32() { return i18n.t('HomePageBenefitsSection.b3_2'); }
    static get competitionSpreeP2() { return i18n.t('competition_spree_p2'); }
    static get advantage1Paragraph() { return i18n.t('advantage_1_paragraph'); }
    static get policyPolicyText63() { return i18n.t('Policy.policy_text_63'); }
    static get policyPolicyText42() { return i18n.t('Policy.policy_text_42'); }
    static get policyPolicyText1031() { return i18n.t('Policy.policy_text_103_1'); }
    static get policyPolicyText91() { return i18n.t('Policy.policy_text_91'); }
    static get policyPolicyText97() { return i18n.t('Policy.policy_text_97'); }
    static get policyPolicyText27() { return i18n.t('Policy.policy_text_27'); }
    static get policyPolicyText58() { return i18n.t('Policy.policy_text_58'); }
    static get policyPolicyText100() { return i18n.t('Policy.policy_text_100'); }
    static get policyPolicyText89() { return i18n.t('Policy.policy_text_89'); }
    static get policyPolicyText70() { return i18n.t('Policy.policy_text_70'); }
    static get policyPolicyText78() { return i18n.t('Policy.policy_text_78'); }
    static get policyPolicyText75() { return i18n.t('Policy.policy_text_75'); }
    static get policyPolicyText37() { return i18n.t('Policy.policy_text_37'); }
    static get policyPolicyText8() { return i18n.t('Policy.policy_text_8'); }
    static get policyPolicyText51() { return i18n.t('Policy.policy_text_51'); }
    static get policyPolicyText109() { return i18n.t('Policy.policy_text_109'); }
    static get policyPolicyText80() { return i18n.t('Policy.policy_text_80'); }
    static get policyPolicyText66() { return i18n.t('Policy.policy_text_66'); }
    static get policyPolicyText22() { return i18n.t('Policy.policy_text_22'); }
    static get policyPolicyText90() { return i18n.t('Policy.policy_text_90'); }
    static get policyPolicyText104() { return i18n.t('Policy.policy_text_104'); }
    static get policyPolicyText41() { return i18n.t('Policy.policy_text_41'); }
    static get policyPolicyText67() { return i18n.t('Policy.policy_text_67'); }
    static get policyPolicyText36() { return i18n.t('Policy.policy_text_36'); }
    static get policyPolicyText96() { return i18n.t('Policy.policy_text_96'); }
    static get policyPolicyText55() { return i18n.t('Policy.policy_text_55'); }
    static get policyPolicyText83() { return i18n.t('Policy.policy_text_83'); }
    static get policyPolicyText7() { return i18n.t('Policy.policy_text_7'); }
    static get policyPolicyText110() { return i18n.t('Policy.policy_text_110'); }
    static get policyPolicyText30() { return i18n.t('Policy.policy_text_30'); }
    static get policyPolicyText33() { return i18n.t('Policy.policy_text_33'); }
    static get policyPolicyText45() { return i18n.t('Policy.policy_text_45'); }
    static get policyPolicyText17() { return i18n.t('Policy.policy_text_17'); }
    static get policyPolicyText43() { return i18n.t('Policy.policy_text_43'); }
    static get policyPolicyText95() { return i18n.t('Policy.policy_text_95'); }
    static get policyPolicyText1032() { return i18n.t('Policy.policy_text_103_2'); }
    static get policyPolicyText12() { return i18n.t('Policy.policy_text_12'); }
    static get policyPolicyText40() { return i18n.t('Policy.policy_text_40'); }
    static get policyPolicyText35() { return i18n.t('Policy.policy_text_35'); }
    static get policyPolicyText38() { return i18n.t('Policy.policy_text_38'); }
    static get policyPolicyText98() { return i18n.t('Policy.policy_text_98'); }
    static get policyPolicyText106() { return i18n.t('Policy.policy_text_106'); }
    static get policyPolicyText21() { return i18n.t('Policy.policy_text_21'); }
    static get policyPolicyText1061() { return i18n.t('Policy.policy_text_106_1'); }
    static get policyPolicyText65() { return i18n.t('Policy.policy_text_65'); }
    static get policyPolicyText61() { return i18n.t('Policy.policy_text_61'); }
    static get policyPolicyText102() { return i18n.t('Policy.policy_text_102'); }
    static get policyPolicyText57() { return i18n.t('Policy.policy_text_57'); }
    static get policyPolicyText79() { return i18n.t('Policy.policy_text_79'); }
    static get policyPolicyText107() { return i18n.t('Policy.policy_text_107'); }
    static get policyPolicyText25() { return i18n.t('Policy.policy_text_25'); }
    static get policyPolicyText31() { return i18n.t('Policy.policy_text_31'); }
    static get policyPolicyText26() { return i18n.t('Policy.policy_text_26'); }
    static get policyPolicyText105() { return i18n.t('Policy.policy_text_105'); }
    static get policyPolicyText69() { return i18n.t('Policy.policy_text_69'); }
    static get policyPolicyText15() { return i18n.t('Policy.policy_text_15'); }
    static get policyPolicyText101() { return i18n.t('Policy.policy_text_101'); }
    static get policyPolicyText108() { return i18n.t('Policy.policy_text_108'); }
    static get policyPolicyText32() { return i18n.t('Policy.policy_text_32'); }
    static get policyPolicyText71() { return i18n.t('Policy.policy_text_71'); }
    static get policyPolicyText111() { return i18n.t('Policy.policy_text_111'); }
    static get policyPolicyText62() { return i18n.t('Policy.policy_text_62'); }
    static get policyPolicyText44() { return i18n.t('Policy.policy_text_44'); }
    static get policyPolicyText20() { return i18n.t('Policy.policy_text_20'); }
    static get policyPolicyText46() { return i18n.t('Policy.policy_text_46'); }
    static get policyPolicyText103() { return i18n.t('Policy.policy_text_103'); }
    static get policyPolicyText5() { return i18n.t('Policy.policy_text_5'); }
    static get policyPolicyText39() { return i18n.t('Policy.policy_text_39'); }
    static get policyPolicyText641() { return i18n.t('Policy.policy_text_64_1'); }
    static get policyPolicyText88() { return i18n.t('Policy.policy_text_88'); }
    static get policyPolicyText16() { return i18n.t('Policy.policy_text_16'); }
    static get policyPolicyText81() { return i18n.t('Policy.policy_text_81'); }
    static get policyPolicyText49() { return i18n.t('Policy.policy_text_49'); }
    static get policyPolicyText11() { return i18n.t('Policy.policy_text_11'); }
    static get policyPolicyText60() { return i18n.t('Policy.policy_text_60'); }
    static get policyPolicyText18() { return i18n.t('Policy.policy_text_18'); }
    static get policyPolicyText29() { return i18n.t('Policy.policy_text_29'); }
    static get policyPolicyText59() { return i18n.t('Policy.policy_text_59'); }
    static get policyPolicyText23() { return i18n.t('Policy.policy_text_23'); }
    static get policyPolicyText56() { return i18n.t('Policy.policy_text_56'); }
    static get policyPolicyText54() { return i18n.t('Policy.policy_text_54'); }
    static get policyPolicyText92() { return i18n.t('Policy.policy_text_92'); }
    static get policyPolicyText93() { return i18n.t('Policy.policy_text_93'); }
    static get policyPolicyText14() { return i18n.t('Policy.policy_text_14'); }
    static get policyPolicyText24() { return i18n.t('Policy.policy_text_24'); }
    static get policyPolicyText72() { return i18n.t('Policy.policy_text_72'); }
    static get policyPolicyText77() { return i18n.t('Policy.policy_text_77'); }
    static get policyPolicyText85() { return i18n.t('Policy.policy_text_85'); }
    static get policyPolicyText86() { return i18n.t('Policy.policy_text_86'); }
    static policyPolicyText4(param1: any) { return i18n.t('Policy.policy_text_4', { param1 }); }
    static get policyPolicyText76() { return i18n.t('Policy.policy_text_76'); }
    static get policyPolicyText74() { return i18n.t('Policy.policy_text_74'); }
    static get policyPolicyText10() { return i18n.t('Policy.policy_text_10'); }
    static get policyPolicyText3() { return i18n.t('Policy.policy_text_3'); }
    static get policyPolicyText82() { return i18n.t('Policy.policy_text_82'); }
    static get policyPolicyText1() { return i18n.t('Policy.policy_text_1'); }
    static get policyPolicyText52() { return i18n.t('Policy.policy_text_52'); }
    static get policyPolicyText64() { return i18n.t('Policy.policy_text_64'); }
    static get policyPolicyText94() { return i18n.t('Policy.policy_text_94'); }
    static get policyPolicyText6() { return i18n.t('Policy.policy_text_6'); }
    static get policyPolicyText13() { return i18n.t('Policy.policy_text_13'); }
    static get policyPolicyText48() { return i18n.t('Policy.policy_text_48'); }
    static get policyPolicyText99() { return i18n.t('Policy.policy_text_99'); }
    static get policyPolicyText84() { return i18n.t('Policy.policy_text_84'); }
    static get policyPolicyText28() { return i18n.t('Policy.policy_text_28'); }
    static get policyPolicyText53() { return i18n.t('Policy.policy_text_53'); }
    static get policyPolicyText2() { return i18n.t('Policy.policy_text_2'); }
    static get policyPolicyText87() { return i18n.t('Policy.policy_text_87'); }
    static get policyPolicyText1033() { return i18n.t('Policy.policy_text_103_3'); }
    static get policyPolicyText9() { return i18n.t('Policy.policy_text_9'); }
    static get policyPolicyText19() { return i18n.t('Policy.policy_text_19'); }
    static get policyPolicyText34() { return i18n.t('Policy.policy_text_34'); }
    static get policyPolicyText73() { return i18n.t('Policy.policy_text_73'); }
    static get policyPolicyText47() { return i18n.t('Policy.policy_text_47'); }
    static get policyPolicyText68() { return i18n.t('Policy.policy_text_68'); }
    static get policyPolicyText10601() { return i18n.t('Policy.policy_text_106_0_1'); }
    static get policyPolicyText50() { return i18n.t('Policy.policy_text_50'); }
    static get back() { return i18n.t('back'); }
    static get companyName() { return i18n.t('company_name'); }
    static get nextStep() { return i18n.t('next_step'); }
    static get homePageAnnoucementCta() { return i18n.t('HomePage.annoucement_cta'); }
    static get homePageCtaTitlePrimary() { return i18n.t('HomePage.cta_title_primary'); }
    static get homePageHowItWorksDisclaimer() { return i18n.t('HomePage.how_it_works_disclaimer'); }
    static get homePageHomePageTitle() { return i18n.t('HomePage.home_page_title'); }
    static get homePageProductOverview() { return i18n.t('HomePage.product_overview'); }
    static get homePageAnnoucementTitle() { return i18n.t('HomePage.annoucement_title'); }
    static get homePageHomePageSubtitle() { return i18n.t('HomePage.home_page_subtitle'); }
    static get homePagePartnerSectionMitLogo() { return i18n.t('HomePagePartnerSection.mit_logo'); }
    static get homePagePartnerSectionCmuLogo() { return i18n.t('HomePagePartnerSection.cmu_logo'); }
    static get homePagePartnerSectionPartnerSubtitle() { return i18n.t('HomePagePartnerSection.partner_subtitle'); }
    static get homePagePartnerSectionPartnerSubtitle2() { return i18n.t('HomePagePartnerSection.partner_subtitle2'); }
    static get homePagePartnerSectionPartnerTitle() { return i18n.t('HomePagePartnerSection.partner_title'); }
    static get benefit3Title() { return i18n.t('benefit_3_title'); }
    static get navOurLeadership() { return i18n.t('nav_our_leadership'); }
    static get benefit1Paragraph() { return i18n.t('benefit_1_paragraph'); }
    static get pageNotFound() { return i18n.t('page_not_found'); }
    static get annoucementSectionWhatsNews() { return i18n.t('AnnoucementSection.whats_news'); }
    static get annoucementSectionLearnMoreAnnoucement() { return i18n.t('AnnoucementSection.learn_more_annoucement'); }
    static get welcome() { return i18n.t('welcome'); }
    static get advantage1Title() { return i18n.t('advantage_1_title'); }
    static get afterTryOn() { return i18n.t('after_try_on'); }
    static get advantage2Title() { return i18n.t('advantage_2_title'); }
    static get aboutUsPageCoreValue2Title() { return i18n.t('AboutUsPage.core_value_2_title'); }
    static get aboutUsPageOurLeaders() { return i18n.t('AboutUsPage.our_leaders'); }
    static get aboutUsPageAboutusPageTitle() { return i18n.t('AboutUsPage.aboutus_page_title'); }
    static get aboutUsPageCoreValue2Desc() { return i18n.t('AboutUsPage.core_value_2_desc'); }
    static get aboutUsPageCoreValue4Desc() { return i18n.t('AboutUsPage.core_value_4_desc'); }
    static get aboutUsPageCoreValue4Title() { return i18n.t('AboutUsPage.core_value_4_title'); }
    static get aboutUsPageSubHeaderAbousUs() { return i18n.t('AboutUsPage.sub_header_abous_us'); }
    static get aboutUsPageTeam3MemberTitle() { return i18n.t('AboutUsPage.team_3_member_title'); }
    static get aboutUsPageTeam8MemberTitle() { return i18n.t('AboutUsPage.team_8_member_title'); }
    static get aboutUsPageCoreValue3Title() { return i18n.t('AboutUsPage.core_value_3_title'); }
    static get aboutUsPageTeam2MemberTitle() { return i18n.t('AboutUsPage.team_2_member_title'); }
    static get aboutUsPageCoreValue5Title() { return i18n.t('AboutUsPage.core_value_5_title'); }
    static get aboutUsPageCoreValue1Desc() { return i18n.t('AboutUsPage.core_value_1_desc'); }
    static get aboutUsPageAboutusPageTitleP2() { return i18n.t('AboutUsPage.aboutus_page_title_p2'); }
    static get aboutUsPageCoreValue5Desc() { return i18n.t('AboutUsPage.core_value_5_desc'); }
    static get aboutUsPageTeam1MemberTitle() { return i18n.t('AboutUsPage.team_1_member_title'); }
    static get aboutUsPageTeam7MemberTitle() { return i18n.t('AboutUsPage.team_7_member_title'); }
    static get aboutUsPageTeam9MemberTitle() { return i18n.t('AboutUsPage.team_9_member_title'); }
    static get aboutUsPageTeam5MemberTitle() { return i18n.t('AboutUsPage.team_5_member_title'); }
    static get aboutUsPageOurTeams() { return i18n.t('AboutUsPage.our_teams'); }
    static get aboutUsPageCoreValue1Title() { return i18n.t('AboutUsPage.core_value_1_title'); }
    static get aboutUsPageTeam6MemberTitle() { return i18n.t('AboutUsPage.team_6_member_title'); }
    static get aboutUsPageCoreValue3Desc() { return i18n.t('AboutUsPage.core_value_3_desc'); }
    static get aboutUsPageCoreValueTitle() { return i18n.t('AboutUsPage.core_value_title'); }
    static get aboutUsPageAboutusPageSubTitle() { return i18n.t('AboutUsPage.aboutus_page_sub_title'); }
    static get aboutUsPageCoreValueDesc() { return i18n.t('AboutUsPage.core_value_desc'); }
    static get aboutUsPageTeam4MemberTitle() { return i18n.t('AboutUsPage.team_4_member_title'); }
    static get competition() { return i18n.t('competition'); }
    static get genericApiError() { return i18n.t('generic_api_error'); }
    static get headerNavContactUs() { return i18n.t('header_nav_contact_us'); }
    static get joinRevolution() { return i18n.t('join_revolution'); }
    static get cookiesModalCookieUsage() { return i18n.t('CookiesModal.cookie_usage'); }
    static get cookiesModalCookieTitlePerformance() { return i18n.t('CookiesModal.cookie_title_performance'); }
    static get cookiesModalRejectAll() { return i18n.t('CookiesModal.reject_all'); }
    static get cookiesModalMoreInformationCookieDetail() { return i18n.t('CookiesModal.more_information_cookie_detail'); }
    static get cookiesModalCookiePreference() { return i18n.t('CookiesModal.cookie_preference'); }
    static get cookiesModalMoreInformationCookie() { return i18n.t('CookiesModal.more_information_cookie'); }
    static get cookiesModalWhyUseCookie() { return i18n.t('CookiesModal.why_use_cookie'); }
    static get cookiesModalCookieColumExpiration() { return i18n.t('CookiesModal.cookie_colum_expiration'); }
    static get cookiesModalSaveChanges() { return i18n.t('CookiesModal.save_changes'); }
    static get cookiesModalWeUseCookies() { return i18n.t('CookiesModal.we_use_cookies'); }
    static get cookiesModalCookieTitleNecessary() { return i18n.t('CookiesModal.cookie_title_necessary'); }
    static get cookiesModalCookiesWhyWeUse() { return i18n.t('CookiesModal.cookies_why_we_use'); }
    static get cookiesModalCookieColumDescription() { return i18n.t('CookiesModal.cookie_colum_description'); }
    static get cookiesModalCookieColumName() { return i18n.t('CookiesModal.cookie_colum_name'); }
    static get cookiesModalAcceptedAll() { return i18n.t('CookiesModal.accepted_all'); }
    static get cookiesModalCookieColumDomain() { return i18n.t('CookiesModal.cookie_colum_domain'); }
    static get toastMessageToastMessageLogout() { return i18n.t('ToastMessage.toast_message_logout'); }
    static get toastMessageToastMessageCantNavigate() { return i18n.t('ToastMessage.toast_message_cant_navigate'); }
    static get toastMessageToastAccountUpdated() { return i18n.t('ToastMessage.toast_account_updated'); }
    static get experienceFlowStep4() { return i18n.t('experience_flow_step4'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit9Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_9_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit8Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_8_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit10Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_10_description'); }
    static get lifeAtSpreeAipageBenefitsSectionOurBenefitsSubtitle() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.our_benefits_subtitle'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit3Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_3_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit6Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_6_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit11Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_11_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit6Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_6_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit1Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_1_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit4Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_4_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit1Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_1_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit12Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_12_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit10Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_10_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit4Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_4_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit8Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_8_title'); }
    static get lifeAtSpreeAipageBenefitsSectionOurBenefits() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.our_benefits'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit12Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_12_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit3Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_3_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit5Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_5_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit9Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_9_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit2Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_2_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit7Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_7_description'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit7Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_7_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit5Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_5_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit2Title() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_2_title'); }
    static get lifeAtSpreeAipageBenefitsSectionSectionBenefit11Description() { return i18n.t('LifeAtSpreeAIPageBenefitsSection.section_benefit_11_description'); }
    static get headerNavPartnerAccessCtaLogin() { return i18n.t('HeaderNav.partner_access_cta_login'); }
    static get headerNavManageAccount() { return i18n.t('HeaderNav.manage_account'); }
    static get headerNavPartnerAccessNoLogin() { return i18n.t('HeaderNav.partner_access_no_login'); }
    static get headerNavPartnerAccess() { return i18n.t('HeaderNav.partner_access'); }
    static get headerNavAccountWaitingApproval() { return i18n.t('HeaderNav.account_waiting_approval'); }
    static get headerNavNavBecomePartner() { return i18n.t('HeaderNav.nav_become_partner'); }
    static get headerNavNavLogin() { return i18n.t('HeaderNav.nav_login'); }
    static get homePageIntellectualSection2Paragraph2() { return i18n.t('HomePageIntellectualSection2.paragraph2'); }
    static get benefit6Paragraph() { return i18n.t('benefit_6_paragraph'); }
    static get loginLoginSpace() { return i18n.t('Login.login_space'); }
    static get loginWelcomeBack() { return i18n.t('Login.welcome_back'); }
    static get loginAccountNotConfirmed() { return i18n.t('Login.account_not_confirmed'); }
    static get loginAccountNotApproved() { return i18n.t('Login.account_not_approved'); }
    static get loginDemoExp() { return i18n.t('Login.demo_exp'); }
    static get loginAccountPasswordIncorrect() { return i18n.t('Login.account_password_incorrect'); }
    static get loginSupportExp() { return i18n.t('Login.support_exp'); }
    static get loginLogin() { return i18n.t('Login.login'); }
    static get loginForgotPassword() { return i18n.t('Login.forgot_password'); }
    static get loginPartnerExp() { return i18n.t('Login.partner_exp'); }
    static get loginNoAccount() { return i18n.t('Login.no_account'); }
    static get loginLoginSpreeai() { return i18n.t('Login.login_spreeai'); }
    static get advantage4Paragraph() { return i18n.t('advantage_4_paragraph'); }
    static get benefitsForCustomers() { return i18n.t('benefits_for_customers'); }

}
export default Copydeck;