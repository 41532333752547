import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PortalHeader } from './PortalHeader';

import styles from './styles/portal-layout.module.scss';
import LayoutFooter from '../Components/layout-footer';

interface PortalLayoutProps {
    children: React.ReactNode;
    isLoggingIn: boolean;
}

export const PortalLayout = ({ children, isLoggingIn }: PortalLayoutProps) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (isLoggingIn) {
        return <div>Logging in...</div>;
    }

    return (
        <div className={styles.portalLayout}>
            <PortalHeader />
            <div>{children}</div>
            <LayoutFooter />
        </div>
    );
};
